<template>
 <LoaderComp/>
</template>
<script>
// import axios from 'axios'
import LoaderComp from "../customize/component/LoaderComp.vue";
import axios from "axios";
export default {
  components:{
   LoaderComp 
  },
  methods:{
    async redirectShopifyEditor(){
      const urlData=this.$route.query;
      if(urlData.key&&urlData.code)
      {
        localStorage.setItem("user_access_token",urlData.code)
        const user=await this.getUser();
        if(user)
       {
        this.$router.push('/customize/create/'+urlData.key);
        return true;
       }
       
      }
        this.$router.push('/Error404');
    },
    async getUser() {
      const response = await axios.get(`/getCurrentUser`);
      if (response.status == 200 && response.data.status == "fail") {
       return false
      } else {
        return response.data.data;
      }
    },
    
  },
  created(){    
    if(Object.entries( this.$route.query).length !== 0 ){
     this.redirectShopifyEditor();
    }
    else
      {
        this.$router.push('/Error404');
      }
  }
} 
</script>